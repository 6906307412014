<template>
  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row text-center">
      <div class="col-lg-12">
        <div class="row">
          <div class="col-lg-3"></div>
          <div class="col-lg-6">
            <caesar-cypher-grid
                v-if="game === 'caesarCypher'"
                :key="game"
                :showed-actions="showedActions"
                :game-id="gameId"
            ></caesar-cypher-grid>
            <minesweeper-grid
                v-else-if="game === 'minesweeper'"
                :key="game"
                :showed-actions="showedActions"
                :game-id="gameId"
            ></minesweeper-grid>
            <math-crossword-grid
                v-else-if="game === 'mathCrossword'"
                :key="game"
                :showed-actions="showedActions"
                :game-id="gameId"
            ></math-crossword-grid>
            <simple-maze-grid
                v-else-if="game === 'simpleMaze'"
                :key="game"
                :showed-actions="showedActions"
                :game-id="gameId"
            ></simple-maze-grid>
            <sudoku9x9
                v-else-if="game === 'sudoku'"
                :key="game"
                :showed-actions="showedActions"
                :game-id="gameId"
            ></sudoku9x9>
            <word-search-grid
                v-else-if="game === 'wordSearch'"
                :key="game"
                :showed-actions="showedActions"
                :game-id="gameId"
            ></word-search-grid>
            <memory-grid
                v-else-if="game === 'memory'"
                :key="game"
                :showed-actions="showedActions"
                :game-id="gameId"
            ></memory-grid>
            <simple-math-grid
                v-else-if="game === 'simpleMath'"
                :key="game"
                :showed-actions="showedActions"
                :game-id="gameId"
            ></simple-math-grid>
            <meme-grid
                v-else-if="game === 'meme'"
                :key="game"
                :showed-actions="showedActions"
                :game-id="gameId"
            ></meme-grid>
            <crossword-grid
                v-else-if="game === 'crossword'"
                :key="game"
                :showed-actions="showedActions"
                :game-id="gameId"
            ></crossword-grid>
            <math-grid
              v-else-if="isEducationMathGame(game)"
              :key="game"
              :showed-actions="showedActions"
              :game="game"
              :game-id="gameId"
            ></math-grid>
          </div>
          <div class="col-lg-3"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {config} from "@/config/config";
import {mapState} from "vuex";
import CrosswordGrid from "@/pages/games/CrosswordGrid";
import CaesarCypherGrid from "@/pages/games/CaesarCypherGrid";
import MathCrosswordGrid from "@/pages/games/MathCrosswordGrid";
import MinesweeperGrid from "@/pages/games/MinesweeperGrid";
import SimpleMazeGrid from "@/pages/games/SimpleMazeGrid";
import Sudoku9x9 from "@/pages/games/Sudoku_9x9";
import WordSearchGrid from "@/pages/games/WordSearchGrid";
import MemoryGrid from "@/pages/games/MemoryGrid";
import SimpleMathGrid from "@/pages/games/SimpleMathGrid";
import MemeGrid from "@/pages/games/MemeGrid";
import MathGrid from "@/pages/games/education/MathGrid.vue";
import {isEducationMathGame} from "@/_helpers/games_helper";

export default {
  name: "Games",
  methods: {isEducationMathGame},
  components: {
    MathGrid,
    CrosswordGrid,
    MemeGrid,
    MemoryGrid,
    CaesarCypherGrid,
    MathCrosswordGrid,
    MinesweeperGrid,
    SimpleMazeGrid,
    Sudoku9x9,
    WordSearchGrid,
    SimpleMathGrid,
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
    showedActions() {
      return ["newGame"];
    },
    game() {
      return this.$route.params.game;
    },
    gameId() {
      return this.$route.params.id;
    },
  },
  created() {
    const validGames = Object.keys(config.games.props);

    if (!validGames.includes(this.$route.params.game)) {
      this.$router.push("/");
    }
  },
};
</script>
